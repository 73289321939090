"use client";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../components/ui/dropdown-menu";
import { SidebarMenuButton } from "../../components/ui/sidebar";
import { AuthContext, AuthContextType } from "../../providers/AuthProvider";
import { useContext } from "react";
import { LogOut, ChevronUp } from "lucide-react";

const LogoutComponent = () => {
  const { signOutUser } = useContext(AuthContext) as AuthContextType;
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <SidebarMenuButton url="Sign out">
          <LogOut /> Sign out
          <ChevronUp className="ml-auto" />
        </SidebarMenuButton>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        side="top"
        className="w-[--radix-popper-anchor-width]"
      >
        <DropdownMenuItem>
          <span>Cancel</span>
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => signOutUser()}>
          <span>Sign out</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default LogoutComponent;
